<template>
  <b-sidebar
      no-close-on-backdrop
      shadow
      no-header
      right
      backdrop
      bg-variant="white"
      sidebar-class="sidebar-lg"
      id="position-new-record-sidebar"
      :backdrop-variant="'dark'"
      :visible="isPositionNewSidebarActive"
      v-if="isPositionNewSidebarActive">
    <template #default="{ hide }">

      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ dataInfo.id === 0 ? 'Pozisyon Ekle' : 'Pozisyon Değiştir' }}</h5>
      </div>

      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

          <validation-provider #default="validationContext" name="Türü" rules="required">
            <b-form-group label="* Türü" :state="getValidationState(validationContext)">
              <v-select v-model="dataInfo.assignmentReasonId" :options="assignmentReasonOptions" :reduce="val => val.value" :clearable="false"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Başlangıç Tarihi" rules="required">
            <b-form-group label="* Başlangıç Tarihi" :state="getValidationState(validationContext)">
              <flatpickr v-model="dataInfo.startDate" class="form-control" :config="{altInput: true, altFormat: 'd.m.Y', dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1}, weekStart: 1}"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Şirket" rules="required">
            <b-form-group label="* Şirket" :state="getValidationState(validationContext)">
              <v-select v-model="dataInfo.corporationId" :options="corporationOptions" :reduce="val => val.value" :clearable="false" v-on:input="getWorkplaces"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="İşyeri" rules="required">
            <b-form-group label="* İşyeri" :state="getValidationState(validationContext)">
              <v-select v-model="dataInfo.workplaceId" :options="workplaceOptions" :reduce="val => val.value" :clearable="false"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Pozisyon" rules="required">
            <b-form-group label="* Pozisyon" :state="getValidationState(validationContext)">
              <v-select v-model="dataInfo.positionId" :options="positionOptions" :reduce="val => val.value" :clearable="false"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Bitiş Tarihi">
            <b-form-group label="Bitiş Tarihi" :state="getValidationState(validationContext)">
              <flatpickr v-model="dataInfo.endDate" class="form-control" :config="{altInput: true, altFormat: 'd.m.Y', dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1}, weekStart: 1}"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Durum" rules="required">
            <b-form-group label="* Durum" :state="getValidationState(validationContext)">
              <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" type="submit">
              <feather-icon icon="SaveIcon" size="16"/>
              <span class="align-middle"> Kaydet</span>
            </b-button>
            <b-button @click="$emit('update:is-position-new-sidebar-active', false)" variant="outline-secondary">
              <feather-icon icon="XCircleIcon" size="16"/>
              <span class="align-middle" role="button"> Vazgeç</span>
            </b-button>
          </div>

        </b-form>
      </validation-observer>

      <Overlay :busy="busy"></Overlay>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {ref, watch} from '@vue/composition-api'
import {required} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import store from "@/store";
import {toastMessage} from "@core/utils/utils";
import {useToast} from "vue-toastification/composition";
import Overlay from "@/components/Overlay.vue";
import router from "@/router";
import flatpickr from "vue-flatpickr-component/src/component"
import {statusOptions} from "@core/utils/filter";

export default {
  components: {
    BFormTextarea,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    vSelect,
    Overlay,
    flatpickr,

    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'isPositionNewSidebarActive',
    event: 'update:is-position-new-sidebar-active',
  },
  props: {
    isPositionNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    recordDataId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, {emit}) {
    const toast = useToast()
    const busy = ref(false)

    const blankRecordData = {
      id: 0,
      userId: router.currentRoute.params.id,
      assignmentReasonId: null,
      startDate: null,
      endDate: null,
      corporationId: null,
      workplaceId: null,
      positionId: null,
      isActive: true,
    }

    const dataInfo = ref(JSON.parse(JSON.stringify(blankRecordData)))
    const resetRecordData = () => {
      dataInfo.value = JSON.parse(JSON.stringify(blankRecordData))
    }

    const assignmentReasonOptions = ref([])
    store.dispatch('store-definition/definitionList', {groupKey: 'ASSIGNMENT_REASON'}).then(response => {
      response.data.data.forEach((value, index) => {
        assignmentReasonOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && dataInfo.value.id === 0) {
          dataInfo.value.assignmentReasonId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    })

    const corporationOptions = ref([])
    busy.value = true
    store.dispatch('store-corporation/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          corporationOptions.value.push({label: value.shortTitle, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const workplaceOptions = ref([])
    const getWorkplaces = (val) => {
      workplaceOptions.value = []

      if (val != null) {
        //workplace
        busy.value = true
        store.dispatch('store-workplace/fetchItems').then(response => {
          response.data.data.forEach((value, index) => {
            if (val === value.corporationId && value.isActive) {
              workplaceOptions.value.push({label: value.shortTitle, value: value.id})
            }
          });
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })
      } else {
        dataInfo.value.workplaceId = null
      }
    }

    const positionOptions = ref([])
    busy.value = true
    store.dispatch('store-position/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          positionOptions.value.push({label: value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const onSubmit = () => {
      busy.value = true
      store.dispatch('store/' + (dataInfo.value.id > 0 ? 'editPosition' : 'addPosition'), {employeeId: router.currentRoute.params.id, dataInfo: dataInfo.value}).then(response => {
        toastMessage(toast, 'success', response.data.message)
        emit('update:is-position-new-sidebar-active', false)
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRecordData)

    watch(() => props.isPositionNewSidebarActive, (dataValue) => {
      if (dataValue) {
        if (props.recordDataId === 0) {
          resetForm()
        } else {
          busy.value = true
          store.dispatch('store/fetchPosition', {employeeId: router.currentRoute.params.id, id: props.recordDataId}).then(response => {
            dataInfo.value = response.data.data
            getWorkplaces(dataInfo.value.corporationId)
          }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
            emit('update:is-position-new-sidebar-active', false)
          }).finally(message => {
            busy.value = false
          })
        }
      } else {
        resetForm()
      }
    })

    return {
      busy,
      dataInfo,
      refFormObserver,
      assignmentReasonOptions,
      corporationOptions,
      workplaceOptions,
      positionOptions,

      statusOptions,

      getValidationState,
      resetForm,
      onSubmit,
      getWorkplaces,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#position-new-record-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

</style>
