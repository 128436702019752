<template>
  <b-tab v-if="$router.currentRoute.params.id > 0 && isVisible">
    <template #title>
      <feather-icon icon="LayersIcon"/>
      <span>Zimmet Bilgileri</span>
    </template>
    <b-card no-body class="border mt-1 mb-0">
      <div class="m-1">
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <v-select v-model="perPage" class="per-page-selector d-inline-block mx-50" :options="perPageOptions" :clearable="false"/>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Arama..."/>
              <b-button-toolbar justify>
                <b-button-group>
                  <b-button @click="reFetchData" variant="outline-primary" size="md">
                    <feather-icon icon="RefreshCcwIcon" size="18"/>
                    <span class="align-middle"></span>
                  </b-button>
                </b-button-group>
              </b-button-toolbar>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
          ref="refListTable"
          primary-key="id"
          empty-text="Kayıt bulunamadı"
          :class="`position-relative table-sticky ${totalCount > 0 && totalCount < 5 ? 'dropdown-h150': ''}`"
          :items="fetchList"
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          responsive striped bordered hover show-empty>

        <template #cell(id)="data">
          <b-link class="font-weight-bold">#{{ data.value }}</b-link>
        </template>

        <template #cell(dateOfIssue)="data">
          <div class="text-nowrap"><date-column-table :data="data.value"></date-column-table></div>
        </template>

        <template #cell(name)="data">
          <div class="text-nowrap">{{ data.value }}</div>
        </template>

        <template #cell(assetCategoryName)="data">
          <div class="text-nowrap">{{ data.value }}</div>
        </template>

        <template #cell(assetBrandName)="data">
          <div class="text-nowrap">{{ data.value }}</div>
        </template>

        <template #cell(modelName)="data">
          <div class="text-nowrap">{{ data.value }}</div>
        </template>

        <template #cell(serialNumber)="data">
          <div class="text-nowrap">{{ data.value }}</div>
        </template>

        <template #cell(assetStatusName)="data">
          <div class="text-nowrap">{{ data.value }}</div>
        </template>

        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon :id="`row-${data.item.id}-prev`" icon="EyeIcon" size="16" @click="previewRecord(data.item)" class="mx-1"/>
          </div>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <footer-table :data-meta="dataMeta"></footer-table>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" v-model="currentPage" :total-rows="totalCount" :per-page="perPage" first-number last-number>

              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>

              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>

            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <preview :is-preview-modal.sync="isPreviewModal" :data-record.sync="dataPreviewRecord"></preview>
      <Overlay :busy="busy"></Overlay>
    </b-card>
  </b-tab>
</template>

<script>
import {BCard, BRow, BCol, BFormInput, BTable, BPagination, BTooltip, BTab, BButtonToolbar, BButtonGroup, BButton, BLink, BBadge, BDropdown, BDropdownItem} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Overlay from "@/components/Overlay.vue";
import {ref} from '@vue/composition-api'
import useList from './useList'
import {BooleanColumnTable, DateColumnTable, ActiveColumnTable, FooterTable, DateTimeColumnTable} from "@/components/Table"
import Preview from "@/views/organization/employee/asset/Preview.vue";

export default {
  name: "AssetList",
  components: {
    BDropdownItem,
    BDropdown,
    BBadge,
    BLink,
    BButton,
    BButtonGroup,
    BButtonToolbar,
    BCard, BRow, BCol, BFormInput, BTable, BPagination, BTooltip, BTab,
    ActiveColumnTable,
    DateColumnTable,
    BooleanColumnTable,
    Overlay,
    FooterTable,
    DateTimeColumnTable,
    vSelect,
    Preview,
  },
  setup() {

    const {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      isVisible,

      fetchList,
      reFetchData,
    } = useList()

    const dataPreviewRecord = ref({})
    const isPreviewModal = ref(false)
    const previewRecord = (dataItem) => {
      dataPreviewRecord.value = dataItem
      isPreviewModal.value = true
    }

    return {
      busy,
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      isVisible,
      isPreviewModal,
      dataPreviewRecord,

      fetchList,
      reFetchData,
      previewRecord,
    }
  }
}
</script>