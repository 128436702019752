<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)" enctype="multipart/form-data">
        <b-tabs pills content-class="border-top" v-model="tabIndex">
          <b-tab active>
            <template #title>
              <feather-icon icon="UserIcon"/>
              <span>Genel Bilgiler</span>
            </template>
            <b-card no-body>
              <div class="d-flex pt-1">
                <feather-icon icon="UserIcon" size="16"/>
                <h5 class="mb-0 ml-50">Genel Bilgiler</h5>
              </div>

              <b-row class="p-1">
                <b-col cols="9" md="9" lg="9">
                  <b-row>
                    <b-col cols="12" md="6" lg="6">
                      <validation-provider #default="validationContext" name="İsim" rules="required">
                        <b-form-group label="* İsim">
                          <b-form-input trim placeholder="İsim" v-model="dataInfo.firstName" :state="getValidationState(validationContext)"/>
                          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12" md="6" lg="6">
                      <validation-provider #default="validationContext" name="Soyisim" rules="required">
                        <b-form-group label="* Soyisim">
                          <b-form-input trim placeholder="Soyisim" v-model="dataInfo.lastName" :state="getValidationState(validationContext)"/>
                          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12" md="6" lg="6">
                      <validation-provider #default="validationContext" name="Şirket Telefon">
                        <b-form-group label="Şirket Telefon">
                          <b-form-input trim placeholder="Şirket Telefon" v-model="dataInfo.phoneNumber" :state="getValidationState(validationContext)"/>
                          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12" md="6" lg="6">
                      <validation-provider #default="validationContext" name="Kişisel Telefon">
                        <b-form-group label="Kişisel Telefon">
                          <b-form-input trim placeholder="Kişisel Telefon" v-model="dataInfo.phoneNumber2" :state="getValidationState(validationContext)"/>
                          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12" md="6" lg="6">
                      <validation-provider #default="validationContext" name="Dahili">
                        <b-form-group label="Dahili">
                          <b-form-input trim placeholder="Dahili" v-model="dataInfo.phoneShortCode" :state="getValidationState(validationContext)"/>
                          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12" md="6" lg="6">
                      <validation-provider #default="validationContext" name="Kısa Kod">
                        <b-form-group label="Kısa Kod">
                          <b-form-input trim placeholder="Kısa Kod" v-model="dataInfo.phoneShortCode2" :state="getValidationState(validationContext)"/>
                          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12" md="6" lg="6">
                      <validation-provider #default="validationContext" name="E-Posta" rules="email">
                        <b-form-group label="E-Posta">
                          <b-form-input trim placeholder="E-Posta" v-model="dataInfo.email" :state="getValidationState(validationContext)"/>
                          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col cols="12" md="6" lg="6">
                      <validation-provider #default="validationContext" name="Durum" rules="required">
                        <b-form-group label="* Durum" :state="getValidationState(validationContext)">
                          <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="3" md="3" lg="3" class="d-flex justify-content-center align-items-center">
                  <div class="mt-1">
                    <b-avatar
                        ref="previewEl"
                        :src="(dataInfo.profilePictureBase64 ? dataInfo.profilePictureBase64 : getApiFile(dataInfo.profilePicture))"
                        :text="avatarText(dataInfo.firstName + ' ' + dataInfo.lastName)"
                        button
                        variant="primary"
                        size="100px"
                        @click="$refs.refInputEl.click()"/>
                    <input
                        ref="refInputEl"
                        type="file"
                        class="d-none"
                        @input="inputImageRenderer">
                  </div>
                </b-col>
              </b-row>

              <div class="d-flex" v-if="dataInfo.id === 0">
                <feather-icon icon="PocketIcon" size="16"/>
                <h5 class="mb-0 ml-50">Organizasyon Bilgileri</h5>
              </div>

              <b-row class="p-1 pt-2" v-if="dataInfo.id === 0">
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Şirket" rules="required">
                    <b-form-group label="* Şirket" :state="getValidationState(validationContext)">
                      <v-select v-model="dataInfo.corporationId" :options="corporationOptions" :reduce="val => val.value" :clearable="false" v-on:input="getWorkplaces"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="İşyeri" rules="required">
                    <b-form-group label="* İşyeri" :state="getValidationState(validationContext)">
                      <v-select v-model="dataInfo.workplaceId" :options="workplaceOptions" :reduce="val => val.value" :clearable="false"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Pozisyon" rules="required">
                    <b-form-group label="* Pozisyon" :state="getValidationState(validationContext)">
                      <v-select v-model="dataInfo.positionId" :options="positionOptions" :reduce="val => val.value" :clearable="false"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <div class="d-flex">
                <feather-icon icon="LayersIcon" size="16"/>
                <h5 class="mb-0 ml-50">Çalışma Bilgileri</h5>
              </div>

              <b-row class="p-1 pt-2">
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="İşe Başlama Tarihi" rules="required">
                    <b-form-group label="* İşe Başlama Tarihi" :state="getValidationState(validationContext)">
                      <flatpickr v-model="dataInfo.startDateOfEmployment" class="form-control" :config="{altInput: true, altFormat: 'd.m.Y', dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1}, weekStart: 1}"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Yaka Tipi">
                    <b-form-group label="Yaka Tipi">
                      <v-select v-model="dataInfo.collarTypeId" :options="collarTypeOptions" :reduce="val => val.value" :clearable="false"/>
                      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Çalışma Tipi">
                    <b-form-group label="Çalışma Tipi">
                      <v-select v-model="dataInfo.laborTypeId" :options="laborTypeOptions" :reduce="val => val.value" :clearable="false"/>
                      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Sözleşme Türü">
                    <b-form-group label="Sözleşme Türü">
                      <v-select v-model="dataInfo.contractTypeId" :options="contractTypeOptions" :reduce="val => val.value" :clearable="false"/>
                      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Sözleşme Bitiş Tarihi">
                    <b-form-group label="Sözleşme Bitiş Tarihi" :state="getValidationState(validationContext)">
                      <flatpickr v-model="dataInfo.contractEndDate" class="form-control" :config="{altInput: true, altFormat: 'd.m.Y', dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1}, weekStart: 1}"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="İşten Çıkış Tarihi">
                    <b-form-group label="İşten Çıkış Tarihi">
                      <flatpickr v-model="dataInfo.terminationDate" class="form-control" :config="{altInput: true, altFormat: 'd.m.Y', dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1}, weekStart: 1}"/>
                      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Çıkış Nedeni">
                    <b-form-group label="İşten Çıkış Nedeni">
                      <v-select v-model="dataInfo.terminationReasonId" :options="terminationReasonOptions" :reduce="val => val.value" :clearable="true"/>
                      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Emekli" rules="required">
                    <b-form-group label="* Emekli" :state="getValidationState(validationContext)">
                      <v-select v-model="dataInfo.retired" :options="yesNoOptions" :reduce="val => val.value" :clearable="false"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <div class="d-flex">
                <feather-icon icon="LockIcon" size="16"/>
                <h5 class="mb-0 ml-50">Diğer Bilgiler</h5>
              </div>

              <b-row class="p-1 pt-2 pb-0">
                <b-col cols="12" md="12" lg="12">
                  <validation-provider #default="validationContext" name="Erişim Bilgileri">
                    <b-form-group label="Erişim Bilgileri" description="Erişim bilgileri belirtilmezse tüm kayıtlar görüntülenecektir." :state="getValidationState(validationContext)">
                      <v-select v-model="dataInfo.userAccessDefinitions" :options="accessDefinitionOptions" :reduce="val => val.value" :clearable="true" multiple="multiple"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

            </b-card>
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="FeatherIcon"/>
              <span>Kişisel Bilgiler</span>
            </template>
            <b-card no-body>
              <div class="d-flex pt-1">
                <feather-icon icon="InfoIcon" size="16"/>
                <h5 class="mb-0 ml-50">Kişisel Bilgiler</h5>
              </div>

              <b-row class="p-1">
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Cinsiyet">
                    <b-form-group label="Cinsiyet" :state="getValidationState(validationContext)">
                      <v-select v-model="dataInfo.genderId" :options="genderOptions" :reduce="val => val.value" :clearable="false"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Doğum Tarihi">
                    <b-form-group label="Doğum Tarihi" :state="getValidationState(validationContext)">
                      <flatpickr v-model="dataInfo.birthDate" class="form-control" :config="{altInput: true, altFormat: 'd.m.Y', dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1}, weekStart: 1}"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Uyruk">
                    <b-form-group label="Uyruk" :state="getValidationState(validationContext)">
                      <v-select v-model="dataInfo.nationalityId" :options="nationalityOptions" :reduce="val => val.value" :clearable="false"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Kimlik No">
                    <b-form-group label="Kimlik No">
                      <b-form-input trim placeholder="Kimlik No" v-model="dataInfo.officialNumber" :state="getValidationState(validationContext)"/>
                      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Kan Grubu">
                    <b-form-group label="Kan Grubu" :state="getValidationState(validationContext)">
                      <v-select v-model="dataInfo.bloodGroupId" :options="bloodGroupOptions" :reduce="val => val.value" :clearable="false"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Engel Derecesi">
                    <b-form-group label="Engel Derecesi" :state="getValidationState(validationContext)">
                      <v-select v-model="dataInfo.disabilityDegreeId" :options="disabilityDegreeOptions" :reduce="val => val.value" :clearable="false"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Medeni Hal">
                    <b-form-group label="Medeni Hal" :state="getValidationState(validationContext)">
                      <v-select v-model="dataInfo.maritalStatusId" :options="maritalStatusOptions" :reduce="val => val.value" :clearable="false"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Çocuk Sayısı">
                    <b-form-group label="Çocuk Sayısı">
                      <b-form-input trim placeholder="Çocuk Sayısı" v-model="dataInfo.childrenCount" :state="getValidationState(validationContext)"/>
                      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Askerlik Durumu">
                    <b-form-group label="Askerlik Durumu" :state="getValidationState(validationContext)">
                      <v-select v-model="dataInfo.militaryServiceStatusId" :options="militaryServiceStatusOptions" :reduce="val => val.value" :clearable="false"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Iban Number">
                    <b-form-group label="Iban Number">
                      <b-form-input trim placeholder="Iban Number" v-model="dataInfo.ibanNumber" :state="getValidationState(validationContext)"/>
                      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <div class="d-flex">
                <feather-icon icon="MapPinIcon" size="16"/>
                <h5 class="mb-0 ml-50">Adres Bilgileri</h5>
              </div>

              <b-row class="p-1 pt-2">
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Ülke">
                    <b-form-group label="Ülke" :state="getValidationState(validationContext)">
                      <v-select v-model="dataInfo.countryId" :options="countryOptions" :reduce="val => val.value" :clearable="false" v-on:input="getCities"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Şehir">
                    <b-form-group label="Şehir" :state="getValidationState(validationContext)">
                      <v-select v-model="dataInfo.cityId" :options="cityOptions" :reduce="val => val.value" :clearable="true" v-on:input="getTowns"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="İlçe">
                    <b-form-group label="İlçe" :state="getValidationState(validationContext)">
                      <v-select v-model="dataInfo.townId" :options="townOptions" :reduce="val => val.value" :clearable="true" v-on:input="getDistrictVillages"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Mahalle">
                    <b-form-group label="Mahalle" :state="getValidationState(validationContext)">
                      <v-select v-model="dataInfo.districtId" :options="districtOptions" :reduce="val => val.value" :clearable="true"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="12" lg="12">
                  <validation-provider #default="validationContext" name="Açık Adres">
                    <b-form-group label="Açık Adres">
                      <b-form-textarea trim placeholder="Açık Adres" v-model="dataInfo.address" :state="getValidationState(validationContext)" rows="4"/>
                      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <div class="d-flex">
                <feather-icon icon="ActivityIcon" size="16"/>
                <h5 class="mb-0 ml-50">Acil Durum Bilgileri</h5>
              </div>

              <b-row class="p-1 pt-2">
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Acil Arama Kişisi">
                    <b-form-group label="Acil Arama Kişisi">
                      <b-form-input trim placeholder="Acil Arama Kişisi" v-model="dataInfo.emergencyName" :state="getValidationState(validationContext)"/>
                      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Acil Arama Telefon">
                    <b-form-group label="Acil Arama Telefon">
                      <b-form-input trim placeholder="Acil Arama Telefon" v-model="dataInfo.emergencyPhone" :state="getValidationState(validationContext)"/>
                      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <div class="d-flex">
                <feather-icon icon="BookIcon" size="16"/>
                <h5 class="mb-0 ml-50">Eğitim Bilgileri</h5>
              </div>

              <b-row class="p-1 pt-2">
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Eğitim Durumu">
                    <b-form-group label="Eğitim Durumu" :state="getValidationState(validationContext)">
                      <v-select v-model="dataInfo.academicLevelId" :options="academicLevelOptions" :reduce="val => val.value" :clearable="false"/>
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Eğitim Kurumu">
                    <b-form-group label="Eğitim Kurumu">
                      <b-form-input trim placeholder="Eğitim Kurumu" v-model="dataInfo.institutionName" :state="getValidationState(validationContext)"/>
                      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <validation-provider #default="validationContext" name="Eğitim Bölümü">
                    <b-form-group label="Eğitim Bölümü">
                      <b-form-input trim placeholder="Eğitim Bölümü" v-model="dataInfo.institutionDepartment" :state="getValidationState(validationContext)"/>
                      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

            </b-card>
          </b-tab>
          <LeaveList></LeaveList>
          <PositionList></PositionList>
          <DocumentList></DocumentList>
          <PayrollList></PayrollList>
          <AssetList></AssetList>
        </b-tabs>
        <action-buttons :back-route="'organization-employee-list'"/>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BAvatar, BTabs, BTab, BFormTextarea, BPagination, BTable, BTooltip, BButtonToolbar, BLink, BButtonGroup, BCardHeader, BCardTitle, BMediaAside, BCardBody, BMedia, BCardText, BMediaBody, BBadge,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {required, email} from '@validations'
import {onUnmounted, ref} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/organization/employee/store"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import {avatarText, getApiFile, statusOptions, yesNoOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import {toastMessage} from "@core/utils/utils"
import definitionModule from "@/views/system/definition/store"
import corporationModule from "@/views/organization/corporation/store"
import workplaceModule from "@/views/organization/workplace/store"
import positionModule from "@/views/organization/position/store"
import addressModule from "@/store/common/address"
import accessModule from "@/views/definition/access/store"
import leaveTypeModule from "@/views/definition/leavetype/store"
import {ActiveColumnTable, DateColumnTable, DateTimeColumnTable, FooterTable} from "@/components/Table";
import flatpickr from "vue-flatpickr-component/src/component"
import LeaveList from "@/views/organization/employee/leave/List.vue";
import PositionList from "@/views/organization/employee/position/List.vue";
import DocumentList from "@/views/organization/employee/document/List.vue";
import PayrollList from "@/views/organization/employee/payroll/List.vue";
import AssetList from "@/views/organization/employee/asset/List.vue";
import {useInputImageRenderer} from "@core/comp-functions/forms/form-utils";

export default {
  components: {
    BBadge,
    BMediaBody,
    BCardText,
    BMedia,
    BCardBody,
    BMediaAside,
    BCardTitle,
    BCardHeader,
    BButtonGroup,
    BLink,
    BButtonToolbar,
    BTooltip,
    BTable,
    BPagination,
    BFormTextarea,
    BTab,
    BTabs,
    BAvatar,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    DateTimeColumnTable,
    flatpickr,
    PositionList,
    DateColumnTable,
    FooterTable,
    ActiveColumnTable,
    vSelect,
    ActionButtons,
    Overlay,
    LeaveList,
    DocumentList,
    PayrollList,
    AssetList,

    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_DEFINITION_MODULE_NAME = 'store-definition'
    const STORE_CORPORATION_MODULE_NAME = 'store-corporation'
    const STORE_WORKPLACE_MODULE_NAME = 'store-workplace'
    const STORE_POSITION_MODULE_NAME = 'store-position'
    const STORE_ADDRESS_MODULE_NAME = 'store-address'
    const STORE_ACCESS_MODULE_NAME = 'store-access'
    const STORE_LEAVE_TYPE_MODULE_NAME = 'store-leave-type'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_DEFINITION_MODULE_NAME, definitionModule)
      store.registerModule(STORE_CORPORATION_MODULE_NAME, corporationModule)
      store.registerModule(STORE_WORKPLACE_MODULE_NAME, workplaceModule)
      store.registerModule(STORE_POSITION_MODULE_NAME, positionModule)
      store.registerModule(STORE_ADDRESS_MODULE_NAME, addressModule)
      store.registerModule(STORE_ACCESS_MODULE_NAME, accessModule)
      store.registerModule(STORE_LEAVE_TYPE_MODULE_NAME, leaveTypeModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_DEFINITION_MODULE_NAME)
        store.unregisterModule(STORE_CORPORATION_MODULE_NAME)
        store.unregisterModule(STORE_WORKPLACE_MODULE_NAME)
        store.unregisterModule(STORE_POSITION_MODULE_NAME)
        store.unregisterModule(STORE_ADDRESS_MODULE_NAME)
        store.unregisterModule(STORE_ACCESS_MODULE_NAME)
        store.unregisterModule(STORE_LEAVE_TYPE_MODULE_NAME)
      }
    })

    const tabIndex = ref(0)

    const toast = useToast()
    const busy = ref(false)

    const dataInfo = ref({
      id: 0,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      phoneNumber2: '',
      email: '',
      corporationId: null,
      workplaceId: null,
      positionId: null,
      genderId: null,
      birthDate: null,
      nationalityId: null,
      officialNumber: null,
      bloodGroupId: null,
      disabilityDegreeId: null,
      maritalStatusId: null,
      childrenCount: null,
      ibanNumber: '',
      militaryServiceStatusId: null,
      academicLevelId: null,
      institutionName: '',
      institutionDepartment: '',
      phoneShortCode: '',
      phoneShortCode2: '',
      emergencyName: '',
      emergencyPhone: '',
      startDateOfEmployment: null,
      terminationDate: null,
      terminationReasonId: null,
      contractTypeId: null,
      contractEndDate: null,
      collarTypeId: null,
      laborTypeId: null,
      countryId: null,
      cityId: null,
      townId: null,
      districtId: null,
      villageId: null,
      address: '',
      isActive: true,
      profilePicture: '',
      profilePictureBase64: null,
      userAccessDefinitions: [],
      retired: false
    })

    const countryOptions = ref([])
    const cityOptions = ref([])
    const townOptions = ref([])
    const districtOptions = ref([])
    const villageOptions = ref([])

    const previewEl = ref(null)
    const refInputEl = ref(null)
    const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => {
      dataInfo.value.profilePictureBase64 = base64
    })

    const resetRecordData = () => {
      dataInfo.value = JSON.parse(JSON.stringify(dataInfo.value))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRecordData)

    const onSubmit = () => {

      if ((dataInfo.value.phoneNumber === null || dataInfo.value.phoneNumber === '') && (dataInfo.value.phoneNumber2 === null || dataInfo.value.phoneNumber2 === '')) {
        toastMessage(toast, 'danger', 'Şirket telefonu veya Kişisel telefon bilgisi boş geçilemez!')
        return
      }

      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'organization-employee-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const workplaceOptions = ref([])
    const getWorkplaces = (val) => {
      workplaceOptions.value = []

      if (val != null) {
        //workplace
        busy.value = true
        store.dispatch('store-workplace/fetchItems').then(response => {
          response.data.data.forEach((value, index) => {
            if (val === value.corporationId && value.isActive) {
              workplaceOptions.value.push({label: value.shortTitle, value: value.id})
            }
          });
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })

      } else {
        dataInfo.value.workplaceId = null
        dataInfo.value.departmentId = null
      }
    }

    const positionOptions = ref([])
    busy.value = true
    store.dispatch('store-position/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          positionOptions.value.push({label: value.code + ' - ' + value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const corporationOptions = ref([])
    busy.value = true
    store.dispatch('store-corporation/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          corporationOptions.value.push({label: value.shortTitle, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const genderOptions = ref([])
    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'GENDER'}).then(response => {
      response.data.data.forEach((value, index) => {
        genderOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.genderId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const collarTypeOptions = ref([])
    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'COLLAR_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        collarTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.collarTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const laborTypeOptions = ref([])
    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'LABOR_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        laborTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.laborTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const terminationReasonOptions = ref([])
    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'TERMINATION_REASON'}).then(response => {
      response.data.data.forEach((value, index) => {
        terminationReasonOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.terminationReasonId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const nationalityOptions = ref([])
    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'NATIONALITY'}).then(response => {
      response.data.data.forEach((value, index) => {
        nationalityOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.nationalityId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const bloodGroupOptions = ref([])
    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'BLOOD_GROUP'}).then(response => {
      response.data.data.forEach((value, index) => {
        bloodGroupOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.bloodGroupId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const disabilityDegreeOptions = ref([])
    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'DISABILITY_DEGREE'}).then(response => {
      response.data.data.forEach((value, index) => {
        disabilityDegreeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.disabilityDegreeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const maritalStatusOptions = ref([])
    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'MARITAL_STATUS'}).then(response => {
      response.data.data.forEach((value, index) => {
        maritalStatusOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.maritalStatusId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const militaryServiceStatusOptions = ref([])
    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'MILITARY_SERVICE_STATUS'}).then(response => {
      response.data.data.forEach((value, index) => {
        militaryServiceStatusOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.militaryServiceStatusId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const academicLevelOptions = ref([])
    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'ACADEMIC_LEVEL'}).then(response => {
      response.data.data.forEach((value, index) => {
        academicLevelOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.academicLevelId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const contractTypeOptions = ref([])
    busy.value = true
    store.dispatch('store-definition/definitionList', {groupKey: 'CONTRACT_TYPE'}).then(response => {
      response.data.data.forEach((value, index) => {
        contractTypeOptions.value.push({label: value.name, value: value.key})
        if (value.isDefault && router.currentRoute.params.id === undefined) {
          dataInfo.value.contractTypeId = value.key
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    busy.value = true
    store.dispatch('store-address/countryList').then(response => {
      response.data.data.forEach((value, index) => {
        countryOptions.value.push({label: value.name, value: value.id})
      });

      if (router.currentRoute.params.id === undefined) {
        getCities(dataInfo.value.countryId)
      }

    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const getCities = (val) => {
      cityOptions.value = []
      townOptions.value = []
      districtOptions.value = []
      villageOptions.value = []

      if (val != null) {
        busy.value = true
        store.dispatch('store-address/cityList', {country: val}).then(response => {
          response.data.data.forEach((value, index) => {
            cityOptions.value.push({label: value.name, value: value.id})
          });
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })
      } else {
        dataInfo.value.cityId = null
        dataInfo.value.townId = null
        dataInfo.value.districtId = null
        dataInfo.value.villageId = null
      }
    }

    const getTowns = (val) => {
      townOptions.value = []
      districtOptions.value = []
      villageOptions.value = []

      if (val != null) {
        busy.value = true
        store.dispatch('store-address/townList', {city: val}).then(response => {
          response.data.data.forEach((value, index) => {
            townOptions.value.push({label: value.name, value: value.id})
          });
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })
      } else {
        dataInfo.value.townId = null
        dataInfo.value.districtId = null
        dataInfo.value.villageId = null
      }
    }

    const getDistrictVillages = (val) => {
      districtOptions.value = []
      villageOptions.value = []

      if (val != null) {
        busy.value = true
        store.dispatch('store-address/districtList', {town: val}).then(response => {
          response.data.data.forEach((value, index) => {
            districtOptions.value.push({label: value.name, value: value.id})
          });
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })

      } else {
        dataInfo.value.districtId = null
        dataInfo.value.villageId = null
      }
    }

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data
        getWorkplaces(dataInfo.value.corporationId)
        getCities(dataInfo.value.countryId)
        if (dataInfo.value.cityId > 0) {
          getTowns(dataInfo.value.cityId)
        }
        if (dataInfo.value.townId > 0) {
          getDistrictVillages(dataInfo.value.townId)
        }
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'organization-employee-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const accessDefinitionOptions = ref([])
    busy.value = true
    store.dispatch('store-access/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          accessDefinitionOptions.value.push({label: value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    return {
      busy,
      dataInfo,
      refFormObserver,
      corporationOptions,
      workplaceOptions,
      positionOptions,
      genderOptions,
      collarTypeOptions,
      laborTypeOptions,
      terminationReasonOptions,
      nationalityOptions,
      bloodGroupOptions,
      disabilityDegreeOptions,
      maritalStatusOptions,
      militaryServiceStatusOptions,
      academicLevelOptions,
      contractTypeOptions,
      countryOptions,
      cityOptions,
      townOptions,
      districtOptions,
      villageOptions,
      accessDefinitionOptions,
      previewEl,
      refInputEl,
      tabIndex,

      statusOptions,
      yesNoOptions,

      onSubmit,
      getValidationState,
      resetForm,
      getApiFile,
      avatarText,
      getWorkplaces,
      getCities,
      getTowns,
      getDistrictVillages,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>