<template>
  <b-modal
      no-close-on-backdrop
      centered
      hide-header-close
      ok-variant="dark"
      title="Kayıt Bilgileri"
      modal-class="modal-dark"
      v-model="isPreviewModal"
      size="lg">

    <b-tabs pills>
      <b-tab active>
        <template #title>
          <feather-icon icon="LayersIcon"/>
          <span>Zimmet Bilgileri</span>
        </template>

        <table class="mt-2 mt-xl-0 w-100">
          <tr class="border-bottom">
            <th class="pb-50"><span class="font-weight-bold">İşlem Tarihi</span></th>
            <td class="pb-50"><date-column-table :data="previewData.dateOfIssue"></date-column-table></td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">İsim</span></th>
            <td class="py-50"><span>{{ previewData.name }}</span></td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Kategori</span></th>
            <td class="py-50"><span>{{ previewData.assetCategoryName }}</span></td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Marka</span></th>
            <td class="py-50"><span>{{ previewData.assetBrandName }}</span></td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Model</span></th>
            <td class="py-50"><span>{{ previewData.modelName }}</span></td>
          </tr>
          <tr class="border-bottom">
            <th class="py-50"><span class="font-weight-bold">Seri No</span></th>
            <td class="py-50"><span>{{ previewData.serialNumber }}</span></td>
          </tr>
          <tr>
            <th class="pt-50"><span class="font-weight-bold">Durum</span></th>
            <td class="pt-50"><span>{{ previewData.assetStatusName }}</span></td>
          </tr>
        </table>

      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="LayersIcon"/>
          <span>Zimmet Geçmişi</span>
        </template>
        <b-card no-body class="border mt-1">
          <b-table
              :items="previewData.transactions"
              :fields="assetHistoryFields"
              class="mb-0"
              striped responsive bordered hover>

            <template #cell(dateOfIssue)="data">
              <div class="text-nowrap"><date-column-table :data="data.value"></date-column-table></div>
            </template>

            <template #cell(name)="data">
              <div class="text-nowrap">{{ data.value }}</div>
            </template>

            <template #cell(assetCategoryName)="data">
              <div class="text-nowrap">{{ data.value }}</div>
            </template>

            <template #cell(assetBrandName)="data">
              <div class="text-nowrap">{{ data.value }}</div>
            </template>

            <template #cell(modelName)="data">
              <div class="text-nowrap">{{ data.value }}</div>
            </template>

            <template #cell(serialNumber)="data">
              <div class="text-nowrap">{{ data.value }}</div>
            </template>

            <template #cell(assetStatusName)="data">
              <div class="text-nowrap">{{ data.value }}</div>
            </template>

          </b-table>
        </b-card>
      </b-tab>
    </b-tabs>

    <template #modal-footer="">
      <b-row class="d-flex justify-content-end align-items-center">
        <b-button class="mr-1" variant="outline-secondary" @click="closeModal">
          <feather-icon icon="XCircleIcon" size="16"/>
          <span class="align-middle" role="button"> Kapat</span>
        </b-button>
      </b-row>
    </template>
    <Overlay :busy="busy"></Overlay>
  </b-modal>
</template>

<script>
import {ref, watch} from '@vue/composition-api'
import {BBadge, BButton, BCard, BFormCheckbox, BModal, BRow, BTab, BTable, BTabs} from "bootstrap-vue";
import {useToast} from "vue-toastification/composition";
import Overlay from "@/components/Overlay.vue";
import store from "@/store";
import {toastMessage} from "@core/utils/utils";
import {recordStatusVariant, recordStatusFormat} from "@core/utils/filter"
import {ActiveColumnTable, DateTimeColumnTable, DateColumnTable, BooleanColumnTable} from "@/components/Table";
import router from "@/router";

export default {
  components: {
    BFormCheckbox, BTable, BCard, BTab, BTabs, BooleanColumnTable,
    DateColumnTable,
    DateTimeColumnTable,
    ActiveColumnTable,
    Overlay,

    BBadge,
    BRow,
    BButton,
    BModal
  },
  model: [
    {
      prop: 'isPreviewModal',
      event: 'update:is-preview-modal',
    },
    {
      prop: 'dataRecord',
      event: 'update:data-record',
    }
  ],
  props: {
    isPreviewModal: {
      type: Boolean,
      required: true,
    },
    dataRecord: {
      type: Object,
      required: true,
    },
  },
  setup(props, {emit}) {
    const toast = useToast()
    const busy = ref(false)
    const previewData = ref({});

    watch(() => props.dataRecord, (dataValue) => {
      if (dataValue.id !== undefined && props.isPreviewModal) {
        busy.value = true
        store.dispatch('store/previewAsset', {employeeId: router.currentRoute.params.id, id: dataValue.id}).then(response => {
          previewData.value = response.data.data
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
          if (error.response.status === 403) {
            emit('update:is-preview-modal', false)
            emit('update:data-record', {})
          }
        }).finally(message => {
          busy.value = false
        })
      }
    })

    const closeModal = () => {
      emit('update:is-preview-modal', false)
      emit('update:data-record', {})
    }

    const assetHistoryFields = [
      {key: 'dateOfIssue', label: 'Tarih', sortable: false},
      {key: 'name', label: 'İsim', sortable: false},
      {key: 'assetCategoryName', label: 'Kategori', sortable: false},
      {key: 'assetBrandName', label: 'Marka', sortable: false},
      {key: 'modelName', label: 'Model', sortable: false},
      {key: 'serialNumber', label: 'Seri No', sortable: false},
      {key: 'assetStatusName', label: 'Durum', sortable: false},
    ]

    return {
      busy,
      previewData,
      assetHistoryFields,

      recordStatusFormat,
      recordStatusVariant,
      closeModal
    }
  },
}
</script>
