<template>
  <b-sidebar
      no-close-on-backdrop
      shadow
      no-header
      right
      backdrop
      bg-variant="white"
      sidebar-class="sidebar-lg"
      id="position-new-record-sidebar"
      :backdrop-variant="'dark'"
      :visible="isPayrollNewSidebarActive"
      v-if="isPayrollNewSidebarActive">
    <template #default="{ hide }">

      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ dataInfo.id === 0 ? 'Bordro Ekle' : 'Bordro Değiştir' }}</h5>
      </div>

      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" enctype="multipart/form-data">
          <validation-provider #default="validationContext" name="Yıl" rules="required">
            <b-form-group label="* Yıl" :state="getValidationState(validationContext)">
              <v-select v-model="dataInfo.year" :options="yearOptions" :reduce="val => val.value" :clearable="false"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Ay" rules="required">
            <b-form-group label="* Ay" :state="getValidationState(validationContext)">
              <v-select v-model="dataInfo.month" :options="monthOptions" :reduce="val => val.value" :clearable="false"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Döküman">
            <b-form-group label="* Döküman">
              <b-form-file id="document" name="document" placeholder="Dosya seçimi yapınız..." drop-placeholder="Dosyanızı sürükleyerek buraya bırakabilirsiniz..."/>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Açıklama">
            <b-form-group label="Açıklama">
              <b-form-textarea trim placeholder="Açıklama" v-model="dataInfo.description" :state="getValidationState(validationContext)" rows="3"/>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Durum" rules="required">
            <b-form-group label="* Durum" :state="getValidationState(validationContext)">
              <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" type="submit">
              <feather-icon icon="SaveIcon" size="16"/>
              <span class="align-middle"> Kaydet</span>
            </b-button>
            <b-button @click="$emit('update:is-payroll-new-sidebar-active', false)" variant="outline-secondary">
              <feather-icon icon="XCircleIcon" size="16"/>
              <span class="align-middle" role="button"> Vazgeç</span>
            </b-button>
          </div>

        </b-form>
      </validation-observer>

      <Overlay :busy="busy"></Overlay>

    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BCol, BFormFile
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {ref, watch} from '@vue/composition-api'
import {required} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import store from "@/store";
import {toastMessage} from "@core/utils/utils";
import {useToast} from "vue-toastification/composition";
import Overlay from "@/components/Overlay.vue";
import router from "@/router";
import {statusOptions} from "@core/utils/filter";

export default {
  components: {
    BFormFile,
    BCol,
    BFormTextarea,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    vSelect,
    Overlay,

    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'isPayrollNewSidebarActive',
    event: 'update:is-payroll-new-sidebar-active',
  },
  props: {
    isPayrollNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    recordDataId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, {emit}) {
    const toast = useToast()
    const busy = ref(false)

    const blankRecordData = {
      id: 0,
      userId: router.currentRoute.params.id,
      year: null,
      month: null,
      description: null,
      isActive: true,
    }

    const dataInfo = ref(JSON.parse(JSON.stringify(blankRecordData)))
    const resetRecordData = () => {
      dataInfo.value = JSON.parse(JSON.stringify(blankRecordData))
    }

    const onSubmit = () => {
      const payrolls = document.getElementById('document').files
      if (payrolls.length > 0) {

        const formData = new FormData();
        for (let i = 0; i < payrolls.length; i++) {
          let file = payrolls[i];
          formData.append('documents[' + i + ']', file);
        }

        formData.append('id', dataInfo.value.id)
        formData.append('userId', dataInfo.value.userId)
        formData.append('year', dataInfo.value.year)
        formData.append('month', dataInfo.value.month)
        formData.append('description', dataInfo.value.description)
        formData.append('isActive', dataInfo.value.isActive)

        busy.value = true
        store.dispatch('store/addPayroll', {
          employeeId: router.currentRoute.params.id,
          dataInfo: formData
        }).then(response => {
          toastMessage(toast, 'success', response.data.message)
          emit('update:is-payroll-new-sidebar-active', false)
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })
      } else {
        toastMessage(toast, 'danger', 'Lütfen dosya seçimi yapınız!')
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetRecordData)

    watch(() => props.isPayrollNewSidebarActive, (dataValue) => {
      if (dataValue) {
        if (props.recordDataId === 0) {
          resetForm()
        } else {
          busy.value = true
          store.dispatch('store/fetchPayroll', {employeeId: router.currentRoute.params.id, id: props.recordDataId}).then(response => {
            dataInfo.value = response.data.data
          }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
            emit('update:is-payroll-new-sidebar-active', false)
          }).finally(message => {
            busy.value = false
          })
        }
      } else {
        resetForm()
      }
    })

    const monthOptions = [
      {label: 'Ocak', value: 1},
      {label: 'Şubat', value: 2},
      {label: 'Mart', value: 3},
      {label: 'Nisan', value: 4},
      {label: 'Mayıs', value: 5},
      {label: 'Haziran', value: 6},
      {label: 'Temmuz', value: 7},
      {label: 'Ağustos', value: 8},
      {label: 'Eylül', value: 9},
      {label: 'Ekim', value: 10},
      {label: 'Kasım', value: 11},
      {label: 'Aralık', value: 12},
    ]

    const yearOptions = [
      {label: '2023', value: 2023},
    ]

    return {
      busy,
      dataInfo,
      refFormObserver,
      monthOptions,
      yearOptions,

      statusOptions,

      getValidationState,
      resetForm,
      onSubmit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#payroll-new-record-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

</style>
