<template>
  <b-tab v-if="$router.currentRoute.params.id > 0 && isVisible">
    <template #title>
      <feather-icon icon="SunsetIcon"/>
      <span>İzin Bilgileri</span>
    </template>
    <b-card no-body class="border mt-1 mb-0">
      <div class="m-1">
        <b-row>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <v-select v-model="perPage" class="per-page-selector d-inline-block mx-50" :options="perPageOptions" :clearable="false"/>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Arama..."/>
              <LeaveDetail :is-leave-new-sidebar-active.sync="isLeaveNewSidebarActive" :record-data-id="leaveRecordDataId"></LeaveDetail>
              <b-button-toolbar justify>
                <b-button-group>
                  <b-button @click="reFetchData" variant="outline-primary" size="md">
                    <feather-icon icon="RefreshCcwIcon" size="18"/>
                    <span class="align-middle"></span>
                  </b-button>
                  <b-button @click="isLeaveNewSidebarActive = true; leaveRecordDataId=0" variant="primary" size="md">
                    <feather-icon icon="PlusCircleIcon" size="18"/>
                    <span class="align-middle"></span>
                  </b-button>
                </b-button-group>
              </b-button-toolbar>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
          ref="refListTable"
          primary-key="id"
          empty-text="Kayıt bulunamadı"
          :class="`position-relative table-sticky ${totalCount > 0 && totalCount < 5 ? 'dropdown-h150': ''}`"
          :items="fetchList"
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          responsive striped bordered hover show-empty>

        <template #cell(id)="data">
          <b-link @click="changeLeave(data.item)" class="font-weight-bold">#{{ data.value }}</b-link>
        </template>

        <template #cell(entitlementDate)="data">
          <div class="text-nowrap">
            <date-column-table :data="data.value"></date-column-table>
          </div>
        </template>

        <template #cell(leaveEntitlementRecordTypeName)="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(leaveTypeName)="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(entitlementDay)="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(usedInDays)="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(remainingDays)="data">
          <div class="text-nowrap">
            {{ parseFloat(data.item.entitlementDay - data.item.usedInDays).toFixed(1) }}
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-dropdown text="Dropdown" variant="link" no-caret toggle-class="p-0">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
              </template>
              <b-dropdown-item @click="changeLeave(data.item)">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">Değiştir</span>
              </b-dropdown-item>
              <b-dropdown-item @click="removeLeave(data.item)">
                <feather-icon icon="TrashIcon"/>
                <span class="align-middle ml-50">Çıkar</span>
              </b-dropdown-item>
            </b-dropdown>
            <feather-icon :id="`row-${data.item.id}-prev`" icon="EyeIcon" size="16" @click="previewRecord(data.item)" class="mx-1"/>
            <b-tooltip title="Görüntüle" class="cursor-pointer" :target="`row-${data.item.id}-prev`"/>
          </div>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <footer-table :data-meta="dataMeta"></footer-table>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" v-model="currentPage" :total-rows="totalCount" :per-page="perPage" first-number last-number>

              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18"/>
              </template>

              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18"/>
              </template>

            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <Overlay :busy="busy"></Overlay>
      <preview :is-preview-modal.sync="isPreviewModal" :data-record.sync="dataPreviewRecord"></preview>
    </b-card>
  </b-tab>
</template>

<script>
import {BCard, BRow, BCol, BFormInput, BTable, BPagination, BTooltip, BTab, BButtonToolbar, BButtonGroup, BButton, BLink, BBadge, BDropdown, BDropdownItem} from 'bootstrap-vue'
import {FooterTable, DateTimeColumnTable} from '@/components/Table';
import vSelect from 'vue-select'
import Overlay from "@/components/Overlay.vue";
import Preview from "@/views/organization/employee/leave/Preview.vue";
import {ref, watch} from '@vue/composition-api'
import useList from './useList'
import LeaveDetail from "@/views/organization/employee/leave/Detail.vue";
import Vue from "vue";
import store from "@/store";
import router from "@/router";
import BooleanColumnTable from "@/components/Table/BooleanColumnTable.vue";
import DateColumnTable from "@/components/Table/DateColumnTable.vue";

export default {
  name: "LeaveList",
  components: {
    DateColumnTable,
    BooleanColumnTable,
    BDropdownItem,
    BDropdown,
    BBadge,
    BLink,
    BButton,
    BButtonGroup,
    BButtonToolbar,
    BCard, BRow, BCol, BFormInput, BTable, BPagination, BTooltip, BTab,
    Overlay, Preview, FooterTable, DateTimeColumnTable, vSelect, LeaveDetail
  },
  setup() {

    const {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      isVisible,

      fetchList,
      reFetchData,
    } = useList()

    const dataPreviewRecord = ref({})
    const isPreviewModal = ref(false)
    const previewRecord = (dataItem) => {
      dataPreviewRecord.value = dataItem
      isPreviewModal.value = true
    }

    const isLeaveNewSidebarActive = ref(false)
    const leaveRecordDataId = ref(0)

    const changeLeave = (dataItem) => {
      leaveRecordDataId.value = dataItem.id
      isLeaveNewSidebarActive.value = true
    }

    const removeLeave = (dataItem) => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Kayıt çıkarılacaktır!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Çıkar',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/removeLeaveInfo', {userId: router.currentRoute.params.id, id: dataItem.id}).then(response => {
            if (response.data.success) {
              reFetchData()
              Vue.swal({
                icon: 'success',
                title: 'Başarılı!',
                text: 'Kayıt çıkarıldı.',
                confirmButtonText: 'Tamam',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            }
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Hata!',
              text: error.response.data.message,
              confirmButtonText: 'Tamam',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    watch(isLeaveNewSidebarActive, (dataValue) => {
      if (dataValue === false) {
        reFetchData()
      }
    })

    return {
      busy,
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      isPreviewModal,
      dataPreviewRecord,
      isVisible,

      isLeaveNewSidebarActive,
      leaveRecordDataId,

      fetchList,
      reFetchData,
      previewRecord,

      changeLeave,
      removeLeave,
    }
  }
}
</script>